<template>
  
    <navbar-en :url="url"></navbar-en>
    <router-view></router-view>
    <my-footer></my-footer>

</template>

<script>

import NavbarEn from '../components/navbar/navbar-en.vue'
import myFooter from '../components/footer/myfooter.vue'

export default {
    name: 'eprocEn',
    components: {
        NavbarEn,
        myFooter
    },
    data () {
        return {
            url: '/eproc'
        }
    }
}
</script>

<style>

</style>